import Table from "../../../../component/table_container/main_table";
import TableHeader from "../../../../component/table_container/table_header";
import TableSubHeader from "../../../../component/table_container/table_sub_header";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import { FiEdit2 } from "react-icons/fi";
import { useState } from "react";
import Http from "../../../../utils/httpClients";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { useParams } from "react-router";
import { check_tab, remove_check } from "../../../../redux/store/tabs";
import DeleteModal from "../../../../component/modal";
import {
  deleteUserDocument,
  getPublicUserDocument,
  getSpecificpublicuser,
  resetUserDocumentId,
  setModalStatus,
} from "../../../../redux/store/public_users";
import Modal from "./modal";

export default function FamilyDocuments() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [document, setDocument] = useState<any>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const public_info = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  const modal_status = useAppSelector(
    (state: any) => state.public_user.modal_status
  );
  const delete_handler = (id: number) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const submitDocument = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    if (document !== undefined && document !== null) {
      if (modal_status === "create") {
        Http.POST(
          "/familydocuments",
          {
            familyprofile_id: id,
            document_id: data.get("document_id"),
            attachment: data.get("attachment"),
          },
          true,
          true
        )
          .then(async (res: any) => {
            setDocument(null);
            await Http.GET("/familyprofiles/" + id, true).then((r) => {
              let data = r.data.data;
              dispatch(
                getSpecificpublicuser({
                  ...data,
                })
              );
            });
            toast.success("Family document has been uploaded!", {
              position: "bottom-right",
            });
            setModal(false);
            dispatch(check_tab(1));
          })
          .catch((err: any) => {
            toast.error("Error while uploading family document!", {
              position: "bottom-right",
            });
          });
      } else if (modal_status === "update") {
        Http.PUT(
          "/familydocuments/" + id,
          {
            public_user_id: id,
            document_id: data.get("document_id"),
            attachment: data.get("attachment"),
          },
          true,
          true
        )
          .then(async (res: any) => {
            setDocument(null);
            await Http.GET("/familyprofiles/" + id, true).then((res) => {
              let data = res.data.data;
              dispatch(
                getSpecificpublicuser({
                  ...data,
                })
              );
            });
            toast.success("Family document has been updated!", {
              position: "bottom-right",
            });
            setModal(false);
          })
          .catch((err: any) => {
            toast.error("Error while updating family document!", {
              position: "bottom-right",
            });
          });
      }
    } else {
      toast.error("Please upload a document!");
    }
  };
  const edit_handler = (id: number) => {
    dispatch(getPublicUserDocument(id));
    dispatch(setModalStatus("update"));
    setModal(true);
  };
  const deleteDocument = () => {
    Http.DELETE("/familydocuments/" + deleteId, true)
      .then((res) => {
        toast.success("Family document has been deleted!", {
          position: "bottom-right",
        });
        setDeleteModal(false);
        dispatch(
          deleteUserDocument({
            name: "familydocuments",
            value: public_info.familydocuments.filter(
              (p: any) => p.id !== deleteId
            ),
          })
        );
        if (public_info.familydocuments.length <= 1) {
          dispatch(remove_check(1));
        }
      })
      .catch((err) => {
        toast.error("Error while deleting user documents", {
          position: "bottom-right",
        });
      });
  };
  const addDocuments = () => {
    dispatch(setModalStatus("create"));
    dispatch(resetUserDocumentId());
    setModal(true);
  };
  const change_document_upload = (e: any) => {
    setDocument(e.target.files[0]);
  };
  const close_modal = () => {
    setModal(false);
    setDocument(null);
  };
  return (
    <div>
      <Modal
        show_modal={modal}
        on_submit={submitDocument}
        document={document}
        change_document_upload={change_document_upload}
        on_close={close_modal}
        title={modal_status === "create" ? "Upload" : "Update"}
      />
      <DeleteModal
        action={deleteDocument}
        open={deleteModal}
        title="document"
        close_modal={() => setDeleteModal(false)}
      />
      <div className="table_wrapper" style={{ backgroundColor: "#fff" }}>
        <div className="component_wrapper table_container">
          <TableHeader
            primary_button_name="
            Add Document"
            table_title="Family Documents"
            sub_title="Manage family documents JPG PDF"
            on_add={addDocuments}
          />
          <TableSubHeader placeholder="Search documents.." />
          <Table table_headings={["S.N.", "Document Name", "Actions"]}>
            {public_info.familydocuments &&
            public_info.familydocuments.length > 0 ? (
              public_info.familydocuments.map((p: any, i: number) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{p.document?.doc_name}</td>
                  <td className="align_items table_icons">
                    <a
                      rel="noreferrer"
                      href={
                        process.env.REACT_APP_BASE_URL?.replace(
                          "api",
                          "storage"
                        ) +
                        "/" +
                        p.upload_path
                      }
                      style={{ height: "21px" }}
                      target="_blank"
                    >
                      <AiOutlineEye />
                    </a>
                    <FiEdit2 onClick={() => edit_handler(p.document?.id)} />
                    <AiOutlineDelete onClick={() => delete_handler(p?.id)} />
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={3} className="no_match_text">
                No family document has been uploaded yet.
              </td>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
}
