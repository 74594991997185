import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import DashBoard from "../pages/dashboard";
import HOC from "../component/hoc";
import Login from "../pages/login";
import NavBar from "../pages/userNavBar";
import Home from "../pages/home";
import Forms from "../component/form";
import SifarisFile from "../pages/sifarisFile";
import Table from "../pages/table/index";
import AddTableHeadings from "../pages/table/componenet/add";
import UpdateTableHeadings from "../pages/table/componenet/update";
import AddFormTitle from "../pages/form/component/add";
import UpdateFormTitle from "../pages/form/component/update";
import Profile from "../pages/profile";
import TemplateForm from "../pages/template_form";
import SifarisAdd from "../pages/sifaris/add_new";
import SifarisTypeTable from "../pages/sifaris/add_new/table";
import SifarisTypeTemplate from "../pages/sifaris/add_new/template";
import SifarisForm from "../pages/form/sifaris_form";
import SifarisTable from "../pages/table/sifaris_table";
import PublicUser from "../pages/user_configuration/public_user";
import { useAppSelector } from "../hooks/hook";
import CreateDynamicForm from "../pages/form/index";
import UpdateDynamicForm from "../pages/form/update";
import CreateDynamicTable from "../pages/table/index";
import UpdateDynamicTable from "../pages/table/update";
import UserProfile from "../pages/profile/component/user_profile";
import ProgressBar from "../component/progress_bar";
import { ProvinceRoutes } from "../pages/federal_structure/pradesh/routes";
import { DistrictRoutes } from "../pages/federal_structure/jilla/routes";
import { LlevelRoutes } from "../pages/federal_structure/local_level/routes";
import { UserRoutes } from "../pages/user_configuration/user/routes";
import { RolesRoutes } from "../pages/user_configuration/roles/routes";
import { PermissionsRoutes } from "../pages/user_configuration/permission/routes";
import OfficeTypeRoutes from "../pages/system_configuration/office_type/routes";
import { OfficeRoutes } from "../pages/system_configuration/office/route";
import { FamilyRelationRoutes } from "../pages/system_configuration/family_relation/routes";
import { DocumentRoutes } from "../pages/system_configuration/document/routes";
import { DesignationRoutes } from "../pages/system_configuration/designation/routes";
import { SifarisTypeRoutes } from "../pages/sifarisType/routes";
import { SifarisDocument } from "../sifaris_docs/routes";
import Overview from "../pages/overview";
const AllRoutes = () => {
  const isLoggedIn = useAppSelector((state) => state.login.isLoggedIn);
  const [role, setRole] = useState<string>("admin");

  return (
    <>
      {isLoggedIn && role === "admin" ? (
        <HOC>
          <ProvinceRoutes />
          <DistrictRoutes />
          <LlevelRoutes />
          <UserRoutes />
          <RolesRoutes />
          <PermissionsRoutes />
          <OfficeTypeRoutes />
          <OfficeRoutes />
          <FamilyRelationRoutes />
          <DocumentRoutes />
          <DesignationRoutes />
          <SifarisTypeRoutes />
          <SifarisDocument />
          <Routes>
            <Route path="/" element={<DashBoard />} />
            <Route
              path="/user_configuration/publicuser"
              element={<PublicUser />}
            />
            <Route path="/overview" element={<Overview />} />
            <Route
              path="/system_configuration/sifaris_type/sifaris_file/:id"
              element={<SifarisFile />}
            />

            <Route
              path="/system_configuration/sifaris_type/table/title/create/:id"
              element={<AddTableHeadings />}
            />
            <Route
              path="/system_configuration/sifaris_type/table/title/update/:id"
              element={<UpdateTableHeadings />}
            />
            <Route
              path="/system_configuration/sifaris_type/form/title/create/:id"
              element={<AddFormTitle />}
            />
            <Route
              path="/system_configuration/sifaris_type/create/:id"
              element={<ProgressBar />}
            />
            <Route
              path="/system_configuration/sifaris_type/form/title/update/:id"
              element={<UpdateFormTitle />}
            />
            <Route
              path="/system_configuration/sifaris_type/form/:id"
              element={<SifarisForm />}
            />
            <Route
              path="/system_configuration/sifaris_type/table/:id"
              element={<SifarisTable />}
            />
            <Route
              path="/system_configuration/sifaris_type/form/dynamic_form/create/:id"
              element={<CreateDynamicForm />}
            />
            <Route
              path="/system_configuration/sifaris_type/form/dynamic_form/update/:id"
              element={<UpdateDynamicForm />}
            />
            <Route
              path="/system_configuration/sifaris_type/dynamic_table/create/:id"
              element={<CreateDynamicTable />}
            />
            <Route
              path="/system_configuration/sifaris_type/dynamic_table/update/:id"
              element={<UpdateDynamicTable />}
            />
            <Route
              path="/system_configuration/sifaris_type/table/:id/:table"
              element={<Table />}
            />
            <Route
              path="/user_configuration/publicuser/update/:id"
              element={<Profile />}
            />
            <Route
              path="/user_configuration/publicuser/create"
              element={<Profile />}
            />
            <Route
              path="/user_configuration/publicuser/view/:id"
              element={<UserProfile />}
            />
            <Route
              path="/system_configuration/sifaris_type/template_form/:id"
              element={<TemplateForm />}
            />
            <Route path="/sifaris/add" element={<SifarisAdd />} />
            <Route
              path="/sifaris/add/template/:id"
              element={<SifarisTypeTable />}
            />
            <Route
              path="/sifaris/add/template/create/:id"
              element={<SifarisTypeTemplate />}
            />
          </Routes>
        </HOC>
      ) : (
        <>
          {isLoggedIn && role === "user" && (
            <>
              <NavBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/forms" element={<Forms />} />
              </Routes>
            </>
          )}
          {!isLoggedIn && <Login />}
        </>
      )}
    </>
  );
};
export default AllRoutes;
